import { EnsureAccountSelected } from '@/Router/Middleware/EnsureAccountSelected';
import { ApprovedPublisher } from '@/Router/Middleware/ApprovedPublisher';
import { EnsureAccountIs } from '@/Router/Middleware/EnsureAccountIs';
import { AcceptedTerms } from '@/Router/Middleware/AcceptedTerms';
import { MaintenanceCheck } from '@/Router/Middleware/MaintenanceCheck';
import { EnsureAuthenticated } from '@/Router/Middleware/EnsureAuthenticated';
import { EnsureIsAdministrator } from '@/Router/Middleware/EnsureIsAdministrator';
import { EnsureHasPermission } from '@/Router/Middleware/EnsureHasPermission';
import { PageTitleHandler } from '@/Router/Middleware/PageTitleHandler';
import { UserType } from '@/Enums/UserType';
import { EnsureEmailVerified } from '@/Router/Middleware/EnsureEmailVerified';

export const MiddlewareGroups = {
    'admin': [
        EnsureAuthenticated,
        EnsureEmailVerified,
        EnsureIsAdministrator,
        EnsureHasPermission,
        PageTitleHandler(UserType.ADMIN)
    ],
    'publisher': [
        EnsureAuthenticated,
        EnsureEmailVerified,
        EnsureAccountSelected(UserType.PUBLISHER),
        PageTitleHandler(UserType.PUBLISHER),
        ApprovedPublisher,
        EnsureAccountIs(UserType.PUBLISHER),
        MaintenanceCheck(UserType.PUBLISHER)],
    'advertiser':  [
        EnsureAuthenticated,
        EnsureEmailVerified,
        EnsureAccountSelected(UserType.ADVERTISER),
        PageTitleHandler(UserType.ADVERTISER),
        EnsureAccountIs(UserType.ADVERTISER),
        AcceptedTerms,
        MaintenanceCheck(UserType.ADVERTISER)
    ],
    'corporation': [
        EnsureAuthenticated,
        EnsureEmailVerified,
        EnsureAccountSelected(UserType.CORPORATION),
        PageTitleHandler(UserType.CORPORATION),
        EnsureAccountIs(UserType.CORPORATION),
        MaintenanceCheck(UserType.CORPORATION)
    ],
};
