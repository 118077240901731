import { useAuthStore } from '@/Stores/AuthStore';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';

export const EnsureEmailVerified: MiddlewareFunction = async (context) => {
    const { isEmailVerified } = useAuthStore();
    if (!isEmailVerified) {
        return { name: 'email-validation' };
    }
    return context.next();
};
