import { PublisherType } from '@/Models/Contracts/PublisherType';
import {
    CurrencyRateResource,
    PublisherProfile,
    PublisherAccount as PublisherAccountContract,
    PublisherAccountOnboarding,
} from '@/stub';

export class PublisherAccount {
    public readonly type = 'PUBLISHER';
    public _currencyRatesMap?: Map<string, number>;

    constructor(
        public id: number,
        public auth_team_id: number,
        public name: string,
        public publisher_type: PublisherType,
        public organizational_type: 'INDIVIDUAL' | 'COMPANY',
        public status: string,
        public postbacks_status: string,
        public publisher_profile: PublisherProfile,
        public onboarding: PublisherAccountOnboarding
    ) {
        this._currencyRatesMap = new Map();
    }

    get currencyRatesMap() {
        if (this._currencyRatesMap?.size === 0) {
            this._currencyRatesMap = new Map(
                this.publisher_profile.rates?.map((rate: CurrencyRateResource) => [rate.currency, rate.rate])
            );
        }

        return this._currencyRatesMap;
    }

    static fromPlain(model: PublisherAccountContract) {
        return new this(
            model.id,
            model.auth_team_id,
            model.name,
            model.publisher_type,
            model.organizational_type,
            model.status,
            model.postbacks_status,
            model.publisher_profile,
            model.onboarding
        );
    }
}
