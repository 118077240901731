<template>
    <div>
        <slot name="title">
            <h2
                class="border-b-4 border-rt-yellow max-w-fit font-bold ac:text-ac-text-headings ac:border-0"
                :class="[sizes[size], dark ? 'text-white' : 'text-dark']"
            >
                <slot />
            </h2>
        </slot>
        <slot name="description">
            <p
                v-if="description"
                class="mt-2 text-gray-500"
            >
                {{ description }}
            </p>
        </slot>
    </div>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
    size?: 'sm' | 'md' | 'lg',
    dark?: boolean,
    description?: string | null
}>(), {
    size: 'lg',
    dark: false,
    description: undefined
});

defineSlots<{
    title?: () => void,
    default?: () => void,
    description?: () => void,
}>();

const sizes = {
    'sm': 'text-xl ac:sm:text-ac-heading-4 ac:text-ac-heading-5',
    'md': 'text-2xl ac:sm:text-ac-heading-3 ac:text-ac-heading-4',
    'lg': 'text-4xl ac:sm:text-ac-heading-2 ac:text-ac-heading-3'
};

</script>
