<template>
    <BaseModal
        v-model:is-opened="opened"
        placement="center"
        h-fit
    >
        <template #content>
            <BaseInput
                v-model="currentMediaName"
                label="Current media name"
            />
            <BaseButton
                :loading="mediaKeyUpdateLoading"
                @click="updateMediaKey"
            >
                Update name
            </BaseButton>
            <BaseInput
                v-model="newMediaName"
                label="Create new media from this"
            />
            <BaseButton
                :loading="mediaKeysCreateLoading"
                @click="createMediaKey"
            >
                Create new one
            </BaseButton>
        </template>
        <template #actions>
            <div class="flex justify-end">
                <BaseButton
                    variant="dark"
                    @click="opened = false"
                >
                    Close
                </BaseButton>
            </div>
        </template>
    </BaseModal>
</template>

<script setup lang="ts">
import { useVModels } from '@vueuse/core';
import BaseModal from '@/Components/Core/BaseModal.vue';
import BaseButton from '@/Components/Core/BaseButton.vue';
import BaseInput from '@/Components/Core/Form/BaseInput.vue';
import { ref, watchEffect } from 'vue';
import { useMediaKeysUpdate } from '@/Services/ApiHooks/Admin/MediaKeys/UseMediaKeysUpdate';
import { useMediaKeysCreate } from '@/Services/ApiHooks/Admin/MediaKeys/UseMediaKeysCreate';

const props = defineProps<{
    modelValue: boolean,
    data: {
        mediaName: string
        parentId: number
        id: number
    },
}>();
const emit = defineEmits(['update:modelValue']);
const { modelValue: opened } = useVModels(props, emit);


const currentMediaName = ref();

watchEffect(() =>  currentMediaName.value = props.data.mediaName);

const {
    mutate: mediaKeyUpdate,
    isLoading: mediaKeyUpdateLoading
} = useMediaKeysUpdate(() => {
    opened.value = false;
});

const updateMediaKey = () => {
    mediaKeyUpdate({
        mediaKeyId: props.data.id,
        adminUpdateMediaKeyRequest: {
            name: currentMediaName.value
        }
    });
};

const newMediaName = ref();

watchEffect(() => {
    if (!opened.value) {
        newMediaName.value = '';
    }
});

const {
    mutate: mediaKeysCreate,
    isLoading: mediaKeysCreateLoading
} = useMediaKeysCreate(() => {
    opened.value = false;
});

const createMediaKey = () => {
    mediaKeysCreate({
        name: newMediaName.value,
        parent_id: props.data.id
    });
};
</script>
