import { RouteLocationRaw } from 'vue-router';

export const buttonDefaults = {
    general: 'inline-flex items-stretch justify-center relative px-4 min-w-[85px] hover:cursor-pointer ',
    text: 'font-semibold',
    transitions: 'transition-colors transition-shadow duration-250',
    disabled: 'cursor-not-allowed opacity-50 pointer-events-none select-none'
};

export const buttonVariants = {
    primary: 'bg-rt-primary text-rt-black border-rt-primary hover:border-b-black active:border-black hover:shadow-[inset_0_-5px_0_0_black] active:border-2',
    secondary: 'text-rt-black border-2 hover:shadow-[inset_0_-3px_0_0_black] active:shadow-[inset_0_0_0_2px_black]',
    accent: 'border-2 bg-rt-green text-rt-white hover:bg-rt-green-600 border-transparent',
    dark: 'text-rt-black border-2 hover:shadow-[inset_0_-3px_0_0_black] active:shadow-[inset_0_0_0_2px_black]',
    darkActive: 'border-2 border-rt-black bg-rt-black text-rt-white hover:border-rt-yellow',
    light: 'border-2 border-rt-white text-white hover:border-rt-yellow',
    danger: 'border-2 bg-red-500 text-rt-white underline font-bold hover:bg-red-600 border-transparent',
    notification: 'border-2 bg-gray-100 border-0 border-b-2 rounded-md border-gray-200 hover:bg-gray-200 hover:border-gray-300 !py-1.5',
    asLink: 'border-2 bg-transparent border-0 text-rt-black hover:bg-gray-100',
    transparent: 'border-2 bg-transparent border-0 text-rt-black hover:bg-gray-100'
};

export const buttonSize = {
    small: 'text-xs py-1.5 px-2 leading-none h-[25px]',
    normal: 'text-md py-0 px-4 h-[32px]',
    medium: 'text-sm py-0 px-4 h-[38px]',
    large: 'text-base py-3 px-6 h-[50px]'
};


export interface ButtonProps {
    to?: RouteLocationRaw | null,
    variant?: keyof typeof buttonVariants,
    size?: keyof typeof buttonSize
    disabled?: boolean
    loading?: boolean
    title?: string | null;
    noCaps?: boolean;
    customClass?: string | null;
    active?: boolean;
}
