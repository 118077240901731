/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { PublisherGetPostbacks200Response } from '../AfClApiModels';
/**
 * PublisherPostbacksApi - axios parameter creator
 * @export
 */
export const PublisherPostbacksApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Export Publisher Postbacks
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [redirectCode] 
         * @param {string} [publisherClickId] 
         * @param {string} [_queryParameters] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherExportPostbacks: async (publisherId: number, from: string, to: string, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', redirectCode?: string, publisherClickId?: string, _queryParameters?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherExportPostbacks', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('publisherExportPostbacks', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('publisherExportPostbacks', 'to', to)
            const localVarPath = `/v1/publishers/{publisherId}/postbacks/export`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (redirectCode !== undefined) {
                localVarQueryParameter['redirect_code'] = redirectCode;
            }

            if (publisherClickId !== undefined) {
                localVarQueryParameter['publisher_click_id'] = publisherClickId;
            }

            if (_queryParameters !== undefined) {
                localVarQueryParameter['query_parameters'] = _queryParameters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Publisher Postbacks
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [redirectCode] 
         * @param {string} [publisherClickId] 
         * @param {string} [_queryParameters] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacks: async (publisherId: number, from: string, to: string, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', redirectCode?: string, publisherClickId?: string, _queryParameters?: string, page?: number, perPage?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'publisherId' is not null or undefined
            assertParamExists('publisherGetPostbacks', 'publisherId', publisherId)
            // verify required parameter 'from' is not null or undefined
            assertParamExists('publisherGetPostbacks', 'from', from)
            // verify required parameter 'to' is not null or undefined
            assertParamExists('publisherGetPostbacks', 'to', to)
            const localVarPath = `/v1/publishers/{publisherId}/postbacks`
                .replace(`{${"publisherId"}}`, encodeURIComponent(String(publisherId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = to;
            }

            if (brandIds) {
                localVarQueryParameter['brand_ids[]'] = brandIds;
            }

            if (eventType !== undefined) {
                localVarQueryParameter['event_type'] = eventType;
            }

            if (redirectCode !== undefined) {
                localVarQueryParameter['redirect_code'] = redirectCode;
            }

            if (publisherClickId !== undefined) {
                localVarQueryParameter['publisher_click_id'] = publisherClickId;
            }

            if (_queryParameters !== undefined) {
                localVarQueryParameter['query_parameters'] = _queryParameters;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (perPage !== undefined) {
                localVarQueryParameter['per_page'] = perPage;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PublisherPostbacksApi - functional programming interface
 * @export
 */
export const PublisherPostbacksApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PublisherPostbacksApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Export Publisher Postbacks
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [redirectCode] 
         * @param {string} [publisherClickId] 
         * @param {string} [_queryParameters] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherExportPostbacks(publisherId: number, from: string, to: string, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', redirectCode?: string, publisherClickId?: string, _queryParameters?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherExportPostbacks(publisherId, from, to, brandIds, eventType, redirectCode, publisherClickId, _queryParameters, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get Publisher Postbacks
         * @param {number} publisherId Numeric ID of the publisher to find
         * @param {string} from date format Y-m-d
         * @param {string} to date format Y-m-d
         * @param {Array<number>} [brandIds] 
         * @param {'signup' | 'deposit'} [eventType] 
         * @param {string} [redirectCode] 
         * @param {string} [publisherClickId] 
         * @param {string} [_queryParameters] 
         * @param {number} [page] 
         * @param {number} [perPage] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async publisherGetPostbacks(publisherId: number, from: string, to: string, brandIds?: Array<number>, eventType?: 'signup' | 'deposit', redirectCode?: string, publisherClickId?: string, _queryParameters?: string, page?: number, perPage?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublisherGetPostbacks200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.publisherGetPostbacks(publisherId, from, to, brandIds, eventType, redirectCode, publisherClickId, _queryParameters, page, perPage, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PublisherPostbacksApi - factory interface
 * @export
 */
export const PublisherPostbacksApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PublisherPostbacksApiFp(configuration)
    return {
        /**
         * 
         * @summary Export Publisher Postbacks
         * @param {PublisherPostbacksApiPublisherExportPostbacksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherExportPostbacks(requestParameters: PublisherPostbacksApiPublisherExportPostbacksRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.publisherExportPostbacks(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.brandIds, requestParameters.eventType, requestParameters.redirectCode, requestParameters.publisherClickId, requestParameters._queryParameters, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Publisher Postbacks
         * @param {PublisherPostbacksApiPublisherGetPostbacksRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publisherGetPostbacks(requestParameters: PublisherPostbacksApiPublisherGetPostbacksRequest, options?: AxiosRequestConfig): AxiosPromise<PublisherGetPostbacks200Response> {
            return localVarFp.publisherGetPostbacks(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.brandIds, requestParameters.eventType, requestParameters.redirectCode, requestParameters.publisherClickId, requestParameters._queryParameters, requestParameters.page, requestParameters.perPage, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for publisherExportPostbacks operation in PublisherPostbacksApi.
 * @export
 * @interface PublisherPostbacksApiPublisherExportPostbacksRequest
 */
export interface PublisherPostbacksApiPublisherExportPostbacksRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly publisherId: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly to: string

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {'signup' | 'deposit'}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly eventType?: 'signup' | 'deposit'

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly redirectCode?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly publisherClickId?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly _queryParameters?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherExportPostbacks
     */
    readonly perPage?: number
}

/**
 * Request parameters for publisherGetPostbacks operation in PublisherPostbacksApi.
 * @export
 * @interface PublisherPostbacksApiPublisherGetPostbacksRequest
 */
export interface PublisherPostbacksApiPublisherGetPostbacksRequest {
    /**
     * Numeric ID of the publisher to find
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly publisherId: number

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly from: string

    /**
     * date format Y-m-d
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly to: string

    /**
     * 
     * @type {Array<number>}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly brandIds?: Array<number>

    /**
     * 
     * @type {'signup' | 'deposit'}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly eventType?: 'signup' | 'deposit'

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly redirectCode?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly publisherClickId?: string

    /**
     * 
     * @type {string}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly _queryParameters?: string

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof PublisherPostbacksApiPublisherGetPostbacks
     */
    readonly perPage?: number
}

/**
 * PublisherPostbacksApi - object-oriented interface
 * @export
 * @class PublisherPostbacksApi
 * @extends {BaseAPI}
 */
export class PublisherPostbacksApi extends BaseAPI {
    /**
     * 
     * @summary Export Publisher Postbacks
     * @param {PublisherPostbacksApiPublisherExportPostbacksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPostbacksApi
     */
    public publisherExportPostbacks(requestParameters: PublisherPostbacksApiPublisherExportPostbacksRequest, options?: AxiosRequestConfig) {
        return PublisherPostbacksApiFp(this.configuration).publisherExportPostbacks(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.brandIds, requestParameters.eventType, requestParameters.redirectCode, requestParameters.publisherClickId, requestParameters._queryParameters, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Publisher Postbacks
     * @param {PublisherPostbacksApiPublisherGetPostbacksRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PublisherPostbacksApi
     */
    public publisherGetPostbacks(requestParameters: PublisherPostbacksApiPublisherGetPostbacksRequest, options?: AxiosRequestConfig) {
        return PublisherPostbacksApiFp(this.configuration).publisherGetPostbacks(requestParameters.publisherId, requestParameters.from, requestParameters.to, requestParameters.brandIds, requestParameters.eventType, requestParameters.redirectCode, requestParameters.publisherClickId, requestParameters._queryParameters, requestParameters.page, requestParameters.perPage, options).then((request) => request(this.axios, this.basePath));
    }
}
