import 'core-js/proposals/reflect-metadata';
import { createApp } from 'vue';
import ControlPanel from '@/ControlPanel.vue';
import { createPinia } from 'pinia';
import { useAuthStore } from '@/Stores/AuthStore';
import { createI18n } from 'vue-i18n';
import { defaultLocale, messages } from '@/Locales';
import { VueQueryPlugin, VueQueryPluginOptions } from '@tanstack/vue-query';
import Toast, { TYPE } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '@vuepic/vue-datepicker/dist/main.css';
import '@/sass/main.scss';
import 'tippy.js/dist/tippy.css';
import '@/css/tailwind.css';
import TranslatedMultiSelect from '@/Components/Core/MultiSelect.vue';
import { router } from '@/Router/Router';
import VueObserveVisibility from 'vue-observe-visibility';
import VueLazyLoad from 'vue3-lazyload';
import { useSentry } from '@/Services/Sentry';
import  Auth0 from '@/Services/Auth0';

declare global {
    interface ImportMeta {
        readonly env: ImportMetaEnv
    }
}

const locales = createI18n({
    messages,
    locale: defaultLocale,
    fallbackLocale: defaultLocale
});

window['hasTranslation'] = (key) => {
    return locales.global.te(key);
};

window['translate'] = (key) => {
    return locales.global.t(key);
};

export const pinia = createPinia();

const vueQueryPluginOptions: VueQueryPluginOptions = {
    queryClientConfig: {
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    },
};

const filterBeforeCreate = (toast, toasts) => (
    toast?.type === TYPE.ERROR
    && toasts.filter(t => t.content === toast.content).length !== 0
) ? false : toast;

const app = createApp(ControlPanel);
useSentry(app, router);
app.use(pinia)
    .use(Auth0)
    .use(VueQueryPlugin, vueQueryPluginOptions)
    .use(Toast, { filterBeforeCreate })
    .use(VueLazyLoad, {})
    .use(VueObserveVisibility)
    .component('VueMultiselect', TranslatedMultiSelect);

const loadingDataPromises: Promise<unknown>[] = [];
loadingDataPromises.push(useAuthStore(pinia).authenticate());
Promise.all(loadingDataPromises).finally(() => {
    app.use(router)
        .use(locales)
        .mount('#ControlPanel');
});
