/* tslint:disable */
/* eslint-disable */
/**
 * AffiliationCloud API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


// May contain unused imports in some cases
// @ts-ignore
import { PublisherAccountOnboarding } from './publisher-account-onboarding';
// May contain unused imports in some cases
// @ts-ignore
import { PublisherProfile } from './publisher-profile';

/**
 * 
 * @export
 * @interface PublisherAccount
 */
export interface PublisherAccount {
    /**
     * 
     * @type {number}
     * @memberof PublisherAccount
     */
    'id': number;
    /**
     * 
     * @type {number}
     * @memberof PublisherAccount
     */
    'auth_team_id': number;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'type'?: PublisherAccountTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'publisher_type': PublisherAccountPublisherTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'organizational_type': PublisherAccountOrganizationalTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'status': PublisherAccountStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PublisherAccount
     */
    'postbacks_status': PublisherAccountPostbacksStatusEnum;
    /**
     * 
     * @type {PublisherProfile}
     * @memberof PublisherAccount
     */
    'publisher_profile': PublisherProfile;
    /**
     * 
     * @type {PublisherAccountOnboarding}
     * @memberof PublisherAccount
     */
    'onboarding': PublisherAccountOnboarding;
}

export const PublisherAccountTypeEnum = {
    Publisher: 'PUBLISHER',
    Advertiser: 'ADVERTISER',
    Corporation: 'CORPORATION'
} as const;

export type PublisherAccountTypeEnum = typeof PublisherAccountTypeEnum[keyof typeof PublisherAccountTypeEnum];
export const PublisherAccountPublisherTypeEnum = {
    Internal: 'INTERNAL',
    External: 'EXTERNAL'
} as const;

export type PublisherAccountPublisherTypeEnum = typeof PublisherAccountPublisherTypeEnum[keyof typeof PublisherAccountPublisherTypeEnum];
export const PublisherAccountOrganizationalTypeEnum = {
    Individual: 'INDIVIDUAL',
    Company: 'COMPANY'
} as const;

export type PublisherAccountOrganizationalTypeEnum = typeof PublisherAccountOrganizationalTypeEnum[keyof typeof PublisherAccountOrganizationalTypeEnum];
export const PublisherAccountStatusEnum = {
    New: 'NEW',
    HasSite: 'HAS_SITE',
    Pending: 'PENDING',
    Live: 'LIVE',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Deleted: 'DELETED'
} as const;

export type PublisherAccountStatusEnum = typeof PublisherAccountStatusEnum[keyof typeof PublisherAccountStatusEnum];
export const PublisherAccountPostbacksStatusEnum = {
    Active: 'ACTIVE',
    Inactive: 'INACTIVE'
} as const;

export type PublisherAccountPostbacksStatusEnum = typeof PublisherAccountPostbacksStatusEnum[keyof typeof PublisherAccountPostbacksStatusEnum];


