import { useAccountStore } from '@/Stores/AccountStore';
import { PublisherAccount } from '@/Models/PublisherAccount';
import { MiddlewareFunction } from 'vue-lite-route/src/Contracts/Middleware/MiddlewareFunction';
import { PublisherAccountStatusEnum } from '@/stub';

export const ApprovedPublisher: MiddlewareFunction = async (context) => {
    const accountStore = useAccountStore();
    if (accountStore.isPublisher) {
        const currentPublisher = accountStore.selectedAccount as PublisherAccount;
        if (currentPublisher.status !== PublisherAccountStatusEnum.Approved && currentPublisher.status !== PublisherAccountStatusEnum.Live) {
            if (currentPublisher.status === PublisherAccountStatusEnum.New) {
                if(['publisher.wizard.sites'].includes(String(context.to.name))) {
                    return await context.next();
                }
                return { name: 'publisher.wizard.sites', params: { accountId: currentPublisher.id } };
            }
        }

        return await context.next();
    }
};
