import { UserContract } from '@/Models/Contracts/UserContract';
import { RoleContract } from '@/Models/Contracts/RoleContract';
import { AccountOption } from '@/Models/Account';
import { UserType } from '@/Enums/UserType';

export class User {
    id: number;
    name: string;
    email: string;
    default_locale: string;
    user_type: string;
    email_verified: boolean;
    roles: RoleContract[];

    constructor(
        id: number,
        name: string,
        email: string,
        default_locale: string,
        user_type: string,
        roles: RoleContract[],
        email_verified: boolean = false
    ) {
        this.id = id;
        this.name = name;
        this.email = email;
        this.default_locale = default_locale;
        this.user_type = user_type;
        this.roles = roles;
        this.email_verified = email_verified;
    }

    static fromPlain(model: UserContract) {
        return new this(
            model.id,
            model.name,
            model.email,
            model.default_locale,
            model.user_type,
            model.roles,
            model.email_verified
        );
    }

    hasRole(roleForCheck: string) {
        return Object.values(this.roles).some(role => role.role === roleForCheck);
    }

    /**
     * Check if the user has the specified permission
     * 
     * if you add the : at the beginning of the permission it means that the permission 
     * should be inside the specificed role.
     * @example hasPermission('access.accounts.corporations') // any user with that permission is allowed to do the action
     * @example hasPermission('admin:access.accounts.corporations') // only user with that permission inside the admin role are allowed to do the action
     * 
     * 
     * @param permissionForCheck 
     * @returns 
     */
    hasPermission(permissionForCheck: string) {
        const [ permission, permissionRole ] = permissionForCheck.split(':').reverse();

        for (const role of this.roles) {
            if (role.permissions.includes(permission)) {
                return !permissionRole || permissionRole.toLowerCase() == role.role.toLowerCase();
            }
        }
        return false;
    }

    accountTypeOptions() {
        const options = <AccountOption[]>[
            { name: 'accounts.type.publisher', id: UserType.PUBLISHER }
        ];

        if (this.hasPermission('access.accounts.corporations')
            || this.hasPermission('corporations.view')
        ) {
            options.push({ name: 'accounts.type.corporation', id: UserType.CORPORATION });
        }

        if (this.hasPermission('access.accounts.advertisers')
            || this.hasPermission('advertisers.view')
        ) {
            options.push({ name: 'accounts.type.advertiser', id: UserType.ADVERTISER });
        }

        return options;
    }
}
