import { Route } from 'vue-lite-route';
import { MiddlewareGroups } from '@/Router/Middleware/MiddlewareGroups';

const DashboardPage = () => import('@/Pages/Shared/DashboardPage.vue');
const CreateSitePage = () => import('@/Pages/Publisher/Sites/SiteCreatePage.vue');
const ProgramsPage = () => import('@/Pages/Publisher/ProgramsPage.vue');
const TopListPage = () => import('@/Pages/Publisher/TopLists/TopListPage.vue');
const TopListsPage = () => import('@/Pages/Publisher/TopLists/TopListsPage.vue');
const TopListsWidgetPage = () => import('@/Pages/Publisher/TopLists/TopListsWidgetPage.vue');
const ReportsGeneralPage = () => import('@/Pages/Publisher/Reports/ReportsGeneralPage.vue');
const ReportsDetailedPage = () => import('@/Pages/Publisher/Reports/ReportsDetailedPage.vue');
const ReportsComparisonDetailedPage = () => import('@/Pages/Publisher/Reports/ReportsComparisonDetailedPage.vue');
const WizardSites = () => import('@/Pages/Publisher/Wizard/WizardSitesPage.vue');
const WizardSummary = () => import('@/Pages/Publisher/Wizard/WizardSummaryPage.vue');
const EarningsPage = () => import('@/Pages/Publisher/EarningsPage.vue');
const ProfileLayout = () => import('@/Layouts/Publisher/ProfileLayout.vue');
const ProfilePage = () => import('@/Pages/Publisher/Profile/ProfilePage.vue');
const ProfileDocumentsPage = () => import('@/Pages/Publisher/Profile/ProfileDocumentsPage.vue');
const ProfileBillingPage = () => import('@/Pages/Publisher/Profile/ProfileBillingPage.vue');
const SitesPage = () => import('@/Pages/Publisher/Sites/SitesPage.vue');
const PublisherApiDocsPage = () => import('@/Pages/Publisher/PublisherApiDocsPage.vue');
const ReportsPostbacksPage = () => import('@/Pages/Publisher/Reports/ReportsPostbacksPage.vue');
const PostbacksEventsPage = () => import('@/Pages/Publisher/Reports/PostbacksEventsPage.vue');
const OnboardingLayout = () => import('@/Pages/Publisher/Wizard/Partials/OnboardingLayout.vue');

export const RegisterPublisherRoutes = () => {
    Route.group({ prefix: '/publishers/:accountId', name: 'publisher', middleware: MiddlewareGroups.publisher }, () => {
        Route.add('/dashboard', { component: DashboardPage }).name('.dashboard');
        Route.childrenGroup('/profile', { action: { component: ProfileLayout } }, () => {
            Route.group({ name: '.profile' }, () => {
                Route.add('', { component: ProfilePage });
                Route.add('documents', { component: ProfileDocumentsPage }).name('.documents');
                Route.add('billing', { component: ProfileBillingPage }).name('.billing');
            });
        }).clearName();
        Route.group({ prefix: '/sites', name: '.sites' }, () => {
            Route.add('', { component: SitesPage });
            Route.add('/create', { component: CreateSitePage }).name('.create');
        });
        Route.add('/programs', { component: ProgramsPage }).name('.programs');
        Route.group({ prefix: '/toplists', name: '.toplists' }, () => {
            Route.add('/', { component: TopListsPage });
            Route.add('/:toplistId?', { component: TopListPage }).name('.toplist');
            Route.add('/:toplistId/widget', { component: TopListsWidgetPage }).name('.widget');
        });
        Route.group({ prefix: '/reports', name: '.reports' }, () => {
            Route.add('/general', { component: ReportsGeneralPage }).name('.general');
            Route.add('/detailed', { component: ReportsDetailedPage }).name('.detailed');
            Route.add('/comparison', { component: ReportsComparisonDetailedPage }).name('.comparison');
            Route.add('/postbacks', { component: ReportsPostbacksPage }).name('.postbacks');
            Route.add('/postbacks-events', { component: PostbacksEventsPage }).name('.postbacks-events');
        });

        Route.add('/earnings', { component: EarningsPage }).name('.earnings');

        Route.add('/api-docs', { component: PublisherApiDocsPage }).name('.api-docs');
    });
};

export const RegisterPublisherOnboardingRoutes = () => {
    Route.group({ prefix: '/publishers/:accountId', name: 'publisher', middleware: MiddlewareGroups.publisher }, () => {
        Route.childrenGroup('/wizard', { action: { component: OnboardingLayout } }, () => {
            Route.group({ name: '.wizard' }, () => {
                Route.add('sites', { component: WizardSites }).name('.sites');
                Route.add('summary', { component: WizardSummary }).name('.summary');
            });
        }).clearName();

    });
};
