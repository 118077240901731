import 'core-js/proposals/reflect-metadata';
import { User } from '@/Models/User';
import { container } from 'tsyringe';
import { BaseApiClient } from '@/Services/ApiClients/BaseApiClient';
import { AfClApi, setAccessToken as AfClApiSetAccessToken } from '@/Services/AfClApi';
import { AuthApiClient } from '@/Services/ApiClients/Shared/AuthApiClient';
import { defineStore } from 'pinia';
import { computed, ref, inject } from 'vue';
import { Auth0Client } from '@auth0/auth0-spa-js';
import { useCookies } from '@vueuse/integrations/useCookies';
import { useLocaleStore } from '@/Stores/LocaleStore';

export const useAuthStore = defineStore('authStore', () => {
    const auth0 = inject<Auth0Client>('auth0');
    const { set, get, remove } = useCookies(['user_access_token']);
    const authUser = ref<User | null>(null);

    const accessToken = ref<string | undefined>();
    const isACAdministrator = computed(() => authUser.value?.hasRole('admin') || false);
    const isAuthenticated = computed(() => auth0?.isAuthenticated && !!authUser.value);
    const isEmailVerified = computed(() => authUser.value?.email_verified ?? false);

    const { setLocale } = useLocaleStore();

    const setUser = (user: User) => {
        authUser.value = user;
    };

    const logOut = async () => {
        remove('user_access_token');
        await AfClApi.auth.logout();
        auth0?.logout({
            logoutParams: {
                returnTo: window.location.origin
            }
        });
    };

    const authenticate = async () => {
        try {
            const existingToken = get('user_access_token');
            if (existingToken) {
                accessToken.value = existingToken;
                BaseApiClient.setAccessToken(accessToken.value ?? '');
                AfClApiSetAccessToken(accessToken.value ?? '');
            }
            else {
                const token = await auth0?.getTokenSilently({ detailedResponse: true });
                if (token) {
                    accessToken.value = token?.access_token;
                    const currentDate = new Date();
                    const expireDate = new Date(currentDate.getTime() + (token.expires_in / 2) * 1000);
                    set('user_access_token', accessToken.value, { expires: expireDate });
                    BaseApiClient.setAccessToken(accessToken.value || '');
                    AfClApiSetAccessToken(accessToken.value || '');
                }
            }
            await loadAuthUser();
        } catch (e) {
            // do nothing, the user will be asked to log in
        }
    };

    const loadAuthUser = async (withoutCache: boolean = false) => {
        if (!authUser.value || withoutCache) {
            const response = await container.resolve(AuthApiClient).getAuthUser();
            setUser(User.fromPlain(response.data));
            setLocale(authUser.value?.default_locale ?? 'en');
        }
    };

    return { authUser, isACAdministrator, setUser, logOut, accessToken, authenticate, isAuthenticated, isEmailVerified, loadAuthUser };
});
